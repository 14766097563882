





















import {Component, Prop, Watch} from 'vue-property-decorator'
import Vue from 'vue'
import {getModule} from "vuex-module-decorators";
import AppStore from "@/store/modules/App";
import store from "@/store";
import {isString} from "lodash";
import {UserApiFactory} from "@/api";
import {axiosInstance, extractError} from "@/utils/axios";
import { EventBus } from "@/utils/EventBus";

const appStore = getModule(AppStore, store)
const userApi = UserApiFactory(undefined, undefined, axiosInstance)

@Component({})
export default class DialogPin extends Vue {
  @Prop({ 'default': 1000, 'required': false }) updateInterval: number

  shouldEnterPin: boolean = false
  submitting: boolean = false
  timeLeft: number = 0
  pin: number|null = null
  timerId: any|null = null

  @Watch('pin')
  updatedPin() {
     if (this.pin && this.pin.toString().length > 4) {
         this.flashError('Pin te lang')
         this.pin = null
     }
  }

  get formattedIdentifiedAt() {
    let date = appStore.identifiedAt;
    if (!date) {
        return ''
    }
    if (isString(date)) {
        date = new Date(date)
    }
    return date.toLocaleTimeString('nl', {hour: '2-digit', minute:'2-digit'});
  }

  get isCorrectLength(): boolean
  {
      if (!this.pin) {
          return false
      }
      return this.pin.toString().length === 4
  }

  handlePin() {
    if (!this.isCorrectLength) {
      return
    }
    this.submitting = true
    userApi.reIdentifyUserCollection(this.pin).then(response => {
      let members = response.data['hydra:member']
      let success = members[0] || false
      let message = members[1] || null
      this.pin = null
      if (success) {
        this.handleCorrectPin()
      } else {
        this.flashError(message)
        this.handleWrongPin()
      }
    }).catch(error => {
      this.flashError(extractError(error))
    })
  }

  handleCorrectPin() {
    this.submitting = false
    appStore.setIdentifiedAt(new Date())
    this.shouldEnterPin = false
    this.setTimer()
  }

  handleWrongPin() {
    appStore.setIdentifiedAt(null)
    this.$router.push({ 'name': 'logout' })
  }

  handleFocus() {
      if (this.$refs['input-pin']) {
          this.$refs['input-pin'].focus()
      }
      let element = document.getElementById('pin_input')
      if (element) {
          element.focus()
          if ('virtualKeyboard' in navigator) {
              navigator.virtualKeyboard.show();
          }
      }
  }

  focusInput() {
    this.handleFocus()
    Vue.nextTick(() => {
        this.handleFocus()
        Vue.nextTick(() => {
            this.handleFocus()
            Vue.nextTick(() => {
                this.handleFocus()
            })
        })
    })
  }

  setTimer() {
    if (this.timerId) {
      clearInterval(this.timerId)
    }
    this.timeLeft = appStore.secondsBeforeEnterPin
    this.timerId = setInterval(() => {
      this.timeLeft -= 1
      if (this.timeLeft < 1) {
        clearInterval(this.timerId)
        this.shouldEnterPin = true
        this.focusInput()
      }
    }, this.updateInterval)
  }

  mounted() {
    Vue.nextTick().then(() => {
      this.setTimer()
    })
  }

  created() {
     EventBus.$on('identified-needed', (event) => {
        this.timeLeft = 0
     })
  }

  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId)
    }
  }
}
